////DEV
let baseApi = "https://syncflow-api-uat.itm.dev"
export const environment = {
    production: true,
    apiUrl: `${baseApi}/api/`,
    rootUrl: "https://syncflow-uat.itm.dev/",
    webRootUrl: `https://syncflow-uat.itm.dev/`,
    clientId: "f1a39844-e6a2-4ae8-a950-f206aeb29ab9",
    main_tenant: "itmfw",
    allApi_scopes:"https://itmfw.onmicrosoft.com/FW08-02-all-API/all.read",

    coreServiceRootUrl: `${baseApi}/core/`,
    crmServiceRootUrl: `${baseApi}/crm/`,
    hrmServiceRootUrl: `${baseApi}/hrm/`,
    identityServiceRootUrl: `http://localhost:6066/`,
    dashboardServiceRootUrl: `${baseApi}/dashboard/`,





    msalConfig: {
        auth: {
            clientId: 'f1a39844-e6a2-4ae8-a950-f206aeb29ab9',
            //authority: 'https://itmfw.b2clogin.com/itmfw.onmicrosoft.com/B2C_1_signIn_fw8',
            authority: 'https://login.microsoftonline.com/common',
            knownAuthorities: ['itmfw.b2clogin.com'],
            redirectUri: '/',
        }
    },
    apiConfig: {
        scopes: ["https://itmfw.onmicrosoft.com/FW08-02-all-API/all.read"],
        endpoint: `${baseApi}/`,
    },
    b2cPolicies: {
        names: {
            signUpSignIn: "B2C_1_signUpSignIn",
            signIn: "B2C_1_SyncFlow_1",
            editProfile: "B2C_1_editProfile",
            passwordReset: "B2C_1_SyncFlow_Password_Reset"
        },
        authorities: {
            signUpSignIn: {
                authority: "https://itmfw.b2clogin.com/itmfw.onmicrosoft.com/B2C_1_signUpSignIn",
            },
            signIn: {
                authority: "https://itmfw.b2clogin.com/itmfw.onmicrosoft.com/B2C_1_SyncFlow_1",
            },
            editProfile: {
                authority: "https://itmfw.b2clogin.com/itmfw.onmicrosoft.com/B2C_1_editProfile"
            },
            passwordReset: {
                authority: "https://itmfw.b2clogin.com/itmfw.onmicrosoft.com/B2C_1_SyncFlow_Password_Reset"
            }
        },
        authorityDomain: "itmfw.b2clogin.com"
    }
};
