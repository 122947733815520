import { Injectable, inject, signal } from '@angular/core';
import { ApiHttpService } from './api-http.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { ItmToastrService } from './itm-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class ItmUserService {
  private readonly _msalService = inject(MsalService);
  constructor() {
    const uniqueId = localStorage.getItem('uniqueId');
    if (uniqueId) {
      this.userIdSig.set(uniqueId);
    }

  }
  // private readonly _toastr = inject(ItmToastrService);

  private readonly httpClient = inject(ApiHttpService);
  private readonly url_GetUser = 'core/user/ui';

  userIdSig = signal<string | undefined>(undefined);

  user$ = toObservable(this.userIdSig)
    .pipe(
      filter(Boolean),
      switchMap(x => this.httpClient.GETT<any>(`${this.url_GetUser}/${x}`)
        .pipe(
          tap(x => {
            if (x.data == undefined) {
              this._msalService.logoutRedirect();
            }
            // console.log(x)
          }),
          map(x => x.data.data.result),
          //shareReplay(1)
        ))
    );
  userSig = toSignal(this.user$);
  setUserId(userId: string) {
    this.userIdSig.set(userId);
  }

  getIdToken(): string {
    return localStorage.getItem('idToken');
  }
  getAccessTokenLocalStorage() {
    return localStorage.getItem('accessToken');
  }

  async getAccessToken() {
    try {
      // Assuming you're already signed in (interceptor likely has token)
      const account = await this._msalService.instance.getActiveAccount();
      // console.log('account', account);

      if (!account) {
        throw new Error("Unexpected: User not signed in"); // Handle unexpected case
      }

      ////console.log("Access token likely already available for account:", account.username);
      // Use the access token directly from the account object (optional)
      // const accessToken = account.accessToken;

      // Or get a new token silently if needed (interceptor might handle this)
      const silentRequest = {
        scopes: [environment.allApi_scopes], // Replace with the scopes your API requires
        account: account, // Specify the account to use for silent acquisition
      };
      ////const silentResult = await this.#msalService.acquireTokenSilent(silentRequest);
      ////console.log("Access token refreshed silently:", silentResult);
      // Use the refreshed access token for your logic here
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle interaction required error (e.g., consent refresh)
        console.error("Silent token acquisition failed (interaction required):", error);
        ////await this.#msalService.loginPopup(silentRequest);
      } else {
        // Handle other errors
        console.error("Unexpected error:", error);
      }
    }
  }
}


